import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                            “We had a wonderful experience! We celebrated our daughter's 3rd birthday. Not only did the owner and the staff help me set up the decorations I brought, but they also had all the tables and chairs ready for us with the theme colors. Throughout the party, the staff was very attentive and helpful to my guests. I was able to host and enjoy the party at the same time. You can't beat that! I highly recommend it and will most likely celebrate there again next year. You guys rocked it! :)”                            </p>
                            <p className="overview">Nancy S.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            “I recently had the pleasure of visiting We Rock The Spectrum with my daughter, and I couldn't be more impressed! This indoor gym truly caters to children of all abilities with its quiet spaces, sensory games, art corner, and costumes. The service was exceptional, with Laura going above and beyond to ensure every child felt welcomed and included. The facilities were clean and well-maintained, creating a safe and enjoyable environment for play. We Rock The Spectrum is definitely a five-star destination for families seeking fun and inclusivity!”                            </p>
                            <p className="overview">Elizabeth S. </p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            “Just wanna thank the owner for being so kind and making this Father's Day great for me and my daughter. GREAT ENVIRONMENT, GREAT STAFF. My daughter felt at home and had so much fun... So many different things to do and such a caring staff. Just want the owner to know what a difference she makes and what a wonderful thing she does for autistic kids. May God bless you and what a wonderful person you are and good heart you have! Thx for making a difference!"                            </p>
                            <p className="overview">
                            Ira M. </p>
                        </div>

                         <div className="item carousel-item">
                            <p className="testimonial">
                            “5 stars is not enough to express how amazing this place is. We had my daughter’s 3-year-old birthday party here, and it could not have been more perfect (which I feel like perfect and toddler birthday don’t belong in the same sentence). Yes, the gym is beautiful and clean, and they have a great-sized party room for food/cake, but it’s so much more than that. The staff is incredible. Starting with the owner, Chanel, who made everything so seamless, easy, and was so accommodating. Ashley, can I hire her? Ashley handled every little issue that came up, really knows how to throw a party and keep it moving, and wow did the kids (and adults) love her. You might as well just take my security deposit for next year because we can’t wait to come back!"                            </p>
                            <p className="overview">
                            CP  </p>
                        </div> 
                       
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
